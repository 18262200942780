import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Image from "next/image";
import searchIcon from "@/public/images/search.svg";
import MenuFilter from "@/public/images/MenuFilter.svg";
import CheckBoxActive from "@/public/images/CheckBoxActive.svg";
import CheckBoxNotActive from "@/public/images/CheckBoxNotActive.svg";
import { ChevronDown } from "lucide-react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
interface Option {
  value: string | number;
  label: string;
}

interface FilterOption {
  label: string;
  placeholder: string;
  options: Option[];
  name: string;
  setSearchLocation?: any;
  searchLocation?: any;
}

interface SearchAndFilterProps {
  defaultValues: Record<string, any>;
  searchPlaceholder: string;
  filters: FilterOption[];
  onSubmit: (values: any) => void;
  t: any;
  locale?: "ar" | "en";
  switchSearch?: boolean;
  setChangeLocalStorage?: any;
}
const useSearchParam = () => {
  const [searchParams, setSearchParams] = useState<URLSearchParams>(
    new URLSearchParams(window.location.search)
  );

  useEffect(() => {
    const handlePopState = () => {
      setSearchParams(new URLSearchParams(window.location.search));
    };
    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, []);

  return [searchParams, setSearchParams] as const;
};

const SearchAndFilter: React.FC<SearchAndFilterProps> = ({
  defaultValues,
  searchPlaceholder,
  filters,
  onSubmit,
  t,
  locale,
  switchSearch,
  setChangeLocalStorage,
}) => {
  const { handleSubmit, control, setValue } = useForm({
    defaultValues,
  });

  const [dropdownOpen, setDropdownOpen] = useState<Record<string, boolean>>({});
  const [selectedOptions, setSelectedOptions] = useState<Record<string, any[]>>(
    {}
  );
  useEffect(() => {
    if (switchSearch) {
      setSelectedOptions({});
    }
  }, [switchSearch]);
  const [searchTerms, setSearchTerms] = useState<Record<string, string>>({});
  const containerRef = useRef<Record<string, HTMLDivElement | null>>({});

  const [openMobile, setOpenMobile] = useState(false);
  const toggleDropdown = (name: string) => {
    setDropdownOpen((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const onselectChange = (values: any): void => {
    try {
      const storageValues: Record<string, string> = {};

      (Object.entries(values) as [keyof any, string | number][]).forEach(
        ([key, value]: [any, string | number]) => {
          // Prepend 'f' to every key
          const modifiedKey = `f${key}`;

          if (value != null && value !== "") {
            const stringValue = value.toString().trim();
            if (stringValue !== "") {
              storageValues[modifiedKey] = stringValue;
            }
          }
        }
      );

      localStorage.setItem("storedValues", JSON.stringify(storageValues));
    } catch (error) {
      console.error("Error storing values in local storage:", error);
    }
  };

  const handleOptionSelect = (filterName: string, option: Option) => {
    setSelectedOptions((prev) => {
      const currentValues = prev[filterName] || [];

      let newValues;
      if (option.value === "all") {
        if (currentValues.includes("all")) {
          newValues = [];
        } else {
          newValues = [
            "all",
            ...(filters
              .find((f) => f.name === filterName)
              ?.options?.map((o) => o.value) || []),
          ];
        }
      } else {
        if (currentValues.includes(option.value)) {
          newValues = currentValues.filter((value) => value !== option.value);
          if (newValues.includes("all")) {
            newValues = newValues.filter((value) => value !== "all");
          }
        } else {
          newValues = [...currentValues, option.value];
          const allOptions =
            filters
              .find((f) => f.name === filterName)
              ?.options?.map((o) => o.value) || [];
          if (newValues.length === allOptions.length) {
            newValues = ["all", ...allOptions];
          }
        }
      }

      setValue(filterName, newValues);
      const updatedSelectedOptions = { ...prev, [filterName]: newValues };

      // Call onselectChange with updated values
      if (switchSearch) onselectChange(updatedSelectedOptions);

      return updatedSelectedOptions;
    });
  };
  const handleClearFilter = (filterName: string) => {
    setSelectedOptions((prev) => {
      const newValues = { ...prev, [filterName]: [] };
      setValue(filterName, []);
      // Call onselectChange with updated values
      if (switchSearch) onselectChange(newValues);
      return newValues;
    });
  };

  const handleSearchTermChange = (filterName: string, term: string) => {
    setSearchTerms((prev) => ({ ...prev, [filterName]: term }));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isOutside = Object.keys(dropdownOpen).some(
        (key) =>
          dropdownOpen[key] &&
          containerRef.current[key] &&
          !containerRef.current[key]!.contains(event.target as Node)
      );

      if (isOutside) {
        setDropdownOpen((prev) =>
          Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: false }), {})
        );
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownOpen]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="hidden md:block  relative mb-6 ">
        <Controller
          name="search"
          control={control}
          render={({ field }) => (
            <input
              {...field}
              type="text"
              className="w-full h-14 bg-gray-100 text-[#6c7278] rounded-lg py-3 px-14"
              placeholder={searchPlaceholder}
            />
          )}
        />
        <Image
          src={searchIcon}
          alt="search"
          width={20}
          height={20}
          className={`${
            locale == "en" ? "left-5" : "right-5"
          } absolute top-1/2 -translate-y-1/2 `}
        />
      </div>
      <div className=" hidden md:flex flex-col md:flex-row items-start justify-between gap-4 ">
        <div className="flex flex-col md:flex-row items-center gap-4 w-full md:w-auto flex-wrap">
          {filters?.map((filter, index) => {
            useEffect(() => {
              filters.forEach((filter) => {
                if (filter?.options?.length === 0) {
                  // Clear the selected option in the form for this filter
                  setValue(filter?.name, ""); // Set the form value to an empty string or null
                  setSelectedOptions((prevSelectedOptions) => ({
                    ...prevSelectedOptions,
                    [filter?.name]: [], // Clear the selected options in the component state
                  }));
                }
              });
            }, [filter.options, setValue]); // Add setValue to the dependency array
            return (
              <div
                className={`w-full md:w-auto max-w-[280px]  ${
                  filter?.options?.length > 0 ? "" : "opacity-50"
                } `}
                key={index}
                ref={(el) => {
                  containerRef.current[filter?.name] = el;
                }}
              >
                <label className="text-sm font-bold text-[#141522] ">
                  {filter?.label}
                </label>

                <div className="relative mt-2">
                  <div className="flex items-center justify-between  min-w-[280px] max-w-[280px] ">
                    <button
                      disabled={filter?.options?.length === 0}
                      type="button"
                      onClick={() => toggleDropdown(filter?.name)}
                      className={`py-2 text-[#747990] relative bg-white rounded-md  w-full max-w-[245px]  cursor-pointer focus:outline-none focus  sm:text-sm`}
                    >
                      <span className="block truncate text-start">
                        {filter?.options?.length > 0 &&
                        selectedOptions[filter?.name]?.length
                          ? filter?.options
                              .filter((option) =>
                                selectedOptions[filter?.name]?.includes(
                                  option?.value
                                )
                              )
                              .map((option) => option.label)
                              .join(", ")
                          : filter?.placeholder}
                      </span>
                      <span
                        className={`${
                          locale == "en" ? "-right-2" : "-left-2 "
                        } absolute inset-y-0 flex items-center  pointer-events-none `}
                      >
                        <ChevronDown className="h-5 w-5 text-gray-400 " />
                      </span>
                    </button>
                    <div className="">
                      {" "}
                      {filter?.name == "university" ||
                      filter?.name == "faculty" ? (
                        ""
                      ) : (
                        <span className="bg-[#53576c] opacity-40 w-[2px] h-[33px] ml-3 hidden md:block"></span>
                      )}
                    </div>
                  </div>

                  {dropdownOpen[filter?.name] && (
                    <div className=" absolute z-[1000]  bg-white  left-2 mt-7 pt-3 py-2 shadow-lg  rounded-[12px]  text-base ring-1 ring-black ring-opacity-5 ">
                      <div className=" overflow-hidden custom-scroll-bar overflow-y-auto min-w-[290px] max-w-[290px] bg-whit max-h-80  ">
                        {filter?.name == "location" && (
                          <div className="w-full p-2 flex items-center justify-center gap-3">
                            <span
                              className="w-full text-center p-2 rounded-xl cursor-pointer"
                              style={
                                filter?.searchLocation == "1"
                                  ? {
                                      backgroundColor: "#0070f3",
                                      color: "white",
                                    }
                                  : {}
                              }
                              onClick={() => {
                                filter.setSearchLocation("1");
                                if (!switchSearch) {
                                  setSearchTerms({}); // Clear search terms when switching
                                  setSelectedOptions({});
                                }
                              }}
                            >
                              {locale === "ar" ? "المحافظة" : "Governorate"}
                            </span>
                            <span
                              onClick={() => {
                                filter?.setSearchLocation("2");
                                if (!switchSearch) {
                                  setSearchTerms({}); // Clear search terms when switching
                                  setSelectedOptions({});
                                }
                              }}
                              style={
                                filter?.searchLocation == "2"
                                  ? {
                                      backgroundColor: "#0070f3",
                                      color: "white",
                                    }
                                  : {}
                              }
                              className="w-full text-center p-2 rounded-xl cursor-pointer"
                            >
                              {locale === "ar" ? "الاقاليم" : "Regions"}
                            </span>
                          </div>
                        )}

                        {filter?.options?.length > 0 && (
                          <input
                            type="text"
                            placeholder={
                              locale === "ar"
                                ? "قم بالبحث هنا  ..."
                                : "Search here ..."
                            }
                            className=" w-[260px] p-2 border mx-[12px] rounded-md border-gray-300 focus:outline-none mb-4 placeholder:text-[#6c7278] placeholder:font-normal  placeholder:text-sm"
                            value={searchTerms[filter?.name] || ""}
                            onChange={(e) =>
                              handleSearchTermChange(
                                filter?.name,
                                e.target.value
                              )
                            }
                          />
                        )}

                        {filter?.options?.length == 0 ? (
                          <p className="text-center text-sm my-4">
                            {locale === "ar" ? "لا توجد نتائج" : "No results"}
                          </p>
                        ) : (
                          <div
                            key="all"
                            onClick={() => {
                              handleOptionSelect(filter?.name, {
                                value: "all",
                                label: "اختر الكل",
                              });
                              switchSearch &&
                                setChangeLocalStorage((prev: boolean) => !prev);
                            }}
                            className="cursor-pointer select-none relative py-2 pl-3 pr-3 mb-1 mx-2 bg-[#f8f9fa] rounded-md hover:bg-[#0070f3] hover:text-white"
                          >
                            {selectedOptions[filter?.name]?.includes("all") ? (
                              <span
                                className={`${
                                  locale == "en"
                                    ? "right-0 pr-4"
                                    : "left-0 pl-4"
                                } absolute inset-y-0  flex items-center  text-[#0070f3] hover:text-white`}
                              >
                                {" "}
                                <Image
                                  src={CheckBoxActive}
                                  alt="check"
                                  width={20}
                                  height={20}
                                />
                              </span>
                            ) : (
                              <span
                                className={`${
                                  locale == "en"
                                    ? "right-0 pr-4"
                                    : "left-0 pl-4"
                                } absolute inset-y-0  flex items-center  text-[#0070f3] hover:text-white`}
                              >
                                {" "}
                                <Image
                                  src={CheckBoxNotActive}
                                  alt="check"
                                  width={20}
                                  height={20}
                                />
                              </span>
                            )}
                            <span
                              className={`block text-[14px] truncate ${
                                selectedOptions[filter?.name]?.includes("all")
                                  ? "font-semibold"
                                  : ""
                              }`}
                            >
                              {locale == "ar" ? " الكل" : "All"}
                            </span>
                          </div>
                        )}
                        {filter?.options
                          ?.filter((option) =>
                            option?.label
                              .toLowerCase()
                              .includes(
                                (searchTerms[filter?.name] || "").toLowerCase()
                              )
                          )
                          .map((option) => (
                            <div
                              key={option.value}
                              onClick={() => {
                                handleOptionSelect(filter?.name, option);
                                switchSearch &&
                                  setChangeLocalStorage(
                                    (prev: boolean) => !prev
                                  );
                              }}
                              className="cursor-pointer   select-none relative py-2 pl-3 pr-3 mb-1 mx-2 bg-[#F8F9FA] rounded-md hover:bg-[#0070f3] hover:text-white"
                            >
                              {selectedOptions[filter?.name]?.includes(
                                option.value
                              ) ? (
                                <span
                                  className={`${
                                    locale == "en"
                                      ? "right-0 pr-4"
                                      : "left-0 pl-4"
                                  } absolute inset-y-0  flex items-center  text-[#0070f3] hover:text-white`}
                                >
                                  <Image
                                    src={CheckBoxActive}
                                    alt="check"
                                    width={20}
                                    height={20}
                                  />
                                </span>
                              ) : (
                                <span
                                  className={`${
                                    locale == "en"
                                      ? "right-0 pr-4"
                                      : "left-0 pl-4"
                                  } absolute inset-y-0  flex items-center  text-[#0070f3] hover:text-white`}
                                >
                                  <Image
                                    src={CheckBoxNotActive}
                                    alt="check"
                                    width={20}
                                    height={20}
                                  />
                                </span>
                              )}
                              <span
                                className={`block text-[14px] truncate ${
                                  selectedOptions[filter?.name]?.includes(
                                    option?.value
                                  )
                                    ? "font-semibold"
                                    : ""
                                }`}
                              >
                                {option?.label}
                              </span>
                            </div>
                          ))}
                        <button
                          type="button"
                          onClick={() => handleClearFilter(filter?.name)}
                          className="block w-full sticky z-[10001] rounded-[12px] bottom-0 text-center text-[#FF9A9A] py-4 bg-white mt-2"
                        >
                          {locale == "ar" ? "الغاء الفلترة" : "Clear Filter"}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <button
          type="submit"
          className="text-base text-white bg-primaryColor py-2 px-6 rounded-lg hover:bg-primaryColor-dark transition-colors w-full md:w-auto"
        >
          {t.filter}
        </button>
      </div>

      {/* phone screen */}

      <div
        className={`md:hidden block  py-2 px-3 rounded-[12px] bg-white  border ${
          openMobile ? "mb-6 " : ""
        }`}
      >
        <div className="relative  ">
          <Controller
            name="search"
            control={control}
            render={({ field }) => (
              <input
                {...field}
                type="text"
                className="w-full h-14 bg-[#eff2f58e] text-[#6c7278] rounded-lg py-3 shadow px-14"
                placeholder={searchPlaceholder}
              />
            )}
          />
          <Image
            src={searchIcon}
            alt="search"
            width={20}
            height={20}
            className={`${
              locale == "en" ? "left-5" : "right-5"
            } absolute top-1/2 -translate-y-1/2 `}
          />
          <Image
            src={MenuFilter}
            alt="MenuFilter"
            width={40}
            height={40}
            onClick={() => setOpenMobile(!openMobile)}
            className={`${
              locale == "ar" ? "left-3" : "right-3"
            } absolute top-1/2 -translate-y-1/2 w-10 h-10 `}
          />
        </div>
      </div>
      {openMobile && (
        <div className=" md:hidden flex flex-col md:flex-row items-start justify-between gap-4 ">
          <div className="flex flex-col md:flex-row items-center gap-4 w-full md:w-auto flex-wrap">
            {filters.map((filter, index) => {
              // useEffect(() => {
              //   filters.forEach((filter) => {
              //     if (filter?.options?.length === 0) {
              //       // Clear the selected option in the form for this filter
              //       setValue(filter?.name, ""); // Set the form value to an empty string or null
              //       setSelectedOptions((prevSelectedOptions) => ({
              //         ...prevSelectedOptions,
              //         [filter?.name]: [], // Clear the selected options in the component state
              //       }));
              //     }
              //   });
              // }, [filter.options, setValue]);
              return (
                <div
                  className="w-full md:w-auto  "
                  key={index}
                  ref={(el) => {
                    containerRef.current[filter?.name] = el;
                  }}
                >
                  <label className="text-sm font-bold text-[#141522] ">
                    {filter?.label}
                  </label>

                  <div className="relative mt-2">
                    <button
                      type="button"
                      onClick={() => toggleDropdown(filter?.name)}
                      className={`${
                        locale == "en" ? "pl-3 py-2" : "pr-3 py-2"
                      } text-[#747990] relative min-w-[280px] w-full bg-white rounded-md   cursor-pointer focus:outline-none focus  sm:text-sm`}
                    >
                      <span className="block truncate text-start max-w-[300px] ">
                        {filter?.options?.length > 0 &&
                        selectedOptions[filter?.name]?.length
                          ? filter?.options
                              .filter((option) =>
                                selectedOptions[filter?.name]?.includes(
                                  option?.value
                                )
                              )
                              .map((option) => option.label)
                              .join(", ")
                          : filter?.placeholder}
                      </span>
                      <span
                        className={`${
                          locale == "en" ? "-right-2" : "-left-2 "
                        } absolute inset-y-0 flex items-center  pointer-events-none `}
                      >
                        <ChevronDown className="h-5 w-5 text-gray-400 " />
                      </span>
                    </button>

                    {dropdownOpen[filter?.name] && (
                      <div className="custom-scroll-bar absolute z-[1000] mt-2 pt-3 min-w-[290px] w-full bg-white shadow-lg max-h-80 rounded-[12px] py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto">
                        {filter?.name == "location" && (
                          <div className="w-full p-2 flex items-center justify-center gap-3">
                            <span
                              className="w-full text-center p-2 rounded-xl cursor-pointer "
                              style={
                                filter?.searchLocation == "1"
                                  ? {
                                      backgroundColor: "#0070f3",
                                      color: "white",
                                    }
                                  : {}
                              }
                              onClick={() => {
                                filter.setSearchLocation("1");
                                if (!switchSearch) {
                                  setSearchTerms({}); // Clear search terms when switching
                                  setSelectedOptions({});
                                }
                              }} // type="button"
                            >
                              {locale === "ar" ? "المحافظة" : "Governorate"}
                            </span>
                            <span
                              onClick={() => {
                                filter.setSearchLocation("2");
                                if (!switchSearch) {
                                  setSearchTerms({}); // Clear search terms when switching
                                  setSelectedOptions({});
                                }
                              }}
                              style={
                                filter?.searchLocation == "2"
                                  ? {
                                      backgroundColor: "#0070f3",
                                      color: "white",
                                    }
                                  : {}
                              }
                              className="w-full text-center p-2 rounded-xl cursor-pointer "
                            >
                              {locale === "ar" ? "الاقاليم" : "Regions"}
                            </span>
                          </div>
                        )}

                        {filter?.options?.length > 0 && (
                          <input
                            type="text"
                            placeholder={
                              locale === "ar"
                                ? "قم بالبحث هنا  ..."
                                : "Search here ..."
                            }
                            className=" w-[90%] p-2 border mx-[12px] rounded-md border-gray-300 focus:outline-none mb-4 placeholder:text-[#6c7278] placeholder:font-normal  placeholder:text-sm"
                            value={searchTerms[filter?.name] || ""}
                            onChange={(e) =>
                              handleSearchTermChange(
                                filter?.name,
                                e.target.value
                              )
                            }
                          />
                        )}

                        <>
                          {filter?.options?.length == 0 ? (
                            <p className="text-center text-sm my-4">
                              {locale === "ar"
                                ? "لا توجد نتائج"
                                : "No results"}
                            </p>
                          ) : (
                            <div
                              key="all"
                              onClick={() => {
                                handleOptionSelect(filter?.name, {
                                  value: "all",
                                  label: "اختر الكل",
                                });
                                switchSearch &&
                                  setChangeLocalStorage(
                                    (prev: boolean) => !prev
                                  );
                              }}
                              className="cursor-pointer select-none relative py-2 pl-3 pr-3 mb-1 mx-2 bg-[#f8f9fa] rounded-md hover:bg-[#0070f3] hover:text-white"
                            >
                              {selectedOptions[filter?.name]?.includes(
                                "all"
                              ) ? (
                                <span
                                  className={`${
                                    locale == "en"
                                      ? "right-0 pr-4"
                                      : "left-0 pl-4"
                                  } absolute inset-y-0  flex items-center  text-[#0070f3] hover:text-white`}
                                >
                                  {" "}
                                  <Image
                                    src={CheckBoxActive}
                                    alt="check"
                                    width={20}
                                    height={20}
                                  />
                                </span>
                              ) : (
                                <span
                                  className={`${
                                    locale == "en"
                                      ? "right-0 pr-4"
                                      : "left-0 pl-4"
                                  } absolute inset-y-0  flex items-center  text-[#0070f3] hover:text-white`}
                                >
                                  {" "}
                                  <Image
                                    src={CheckBoxNotActive}
                                    alt="check"
                                    width={20}
                                    height={20}
                                  />
                                </span>
                              )}
                              <span
                                className={`block text-[14px] truncate ${
                                  selectedOptions[filter?.name]?.includes("all")
                                    ? "font-semibold"
                                    : ""
                                }`}
                              >
                                {locale == "ar" ? " الكل" : "All"}
                              </span>
                            </div>
                          )}
                        </>

                        {filter?.options
                          ?.filter((option) =>
                            option?.label
                              .toLowerCase()
                              .includes(
                                (searchTerms[filter?.name] || "").toLowerCase()
                              )
                          )
                          .map((option) => (
                            <div
                              key={option.value}
                              onClick={() => {
                                handleOptionSelect(filter?.name, option);
                                switchSearch &&
                                  setChangeLocalStorage(
                                    (prev: boolean) => !prev
                                  );
                              }}
                              className="cursor-pointer select-none relative py-2 pl-3 pr-3 mb-1 mx-2 bg-[#F8F9FA] rounded-md hover:bg-[#0070f3] hover:text-white"
                            >
                              {selectedOptions[filter?.name]?.includes(
                                option.value
                              ) ? (
                                <span
                                  className={`${
                                    locale == "en"
                                      ? "right-0 pr-4"
                                      : "left-0 pl-4"
                                  } absolute inset-y-0  flex items-center  text-[#0070f3] hover:text-white`}
                                >
                                  <Image
                                    src={CheckBoxActive}
                                    alt="check"
                                    width={20}
                                    height={20}
                                  />
                                </span>
                              ) : (
                                <span
                                  className={`${
                                    locale == "en"
                                      ? "right-0 pr-4"
                                      : "left-0 pl-4"
                                  } absolute inset-y-0  flex items-center  text-[#0070f3] hover:text-white`}
                                >
                                  <Image
                                    src={CheckBoxNotActive}
                                    alt="check"
                                    width={20}
                                    height={20}
                                  />
                                </span>
                              )}
                              <span
                                className={`block text-[14px] truncate ${
                                  selectedOptions[filter?.name]?.includes(
                                    option?.value
                                  )
                                    ? "font-semibold"
                                    : ""
                                }`}
                              >
                                {option?.label}
                              </span>
                            </div>
                          ))}
                        <button
                          type="button"
                          onClick={() => handleClearFilter(filter?.name)}
                          className="block w-full sticky z-[10001] -bottom-1 text-center text-[#FF9A9A] py-4 bg-white mt-2"
                        >
                          {locale == "ar" ? "الغاء الفلترة" : "Clear Filter"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <button
            type="submit"
            className="text-base text-white bg-primaryColor py-2 px-6 rounded-lg hover:bg-primaryColor-dark transition-colors w-full md:w-auto"
          >
            {t.filter}
          </button>
        </div>
      )}
    </form>
  );
};

export default SearchAndFilter;
