import { apiFavoritesSlice } from "@/redux/app/api/apiSlice";

export const profileApiSlice = apiFavoritesSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Favorites
    addToUniversitiesFavorites: builder.mutation({
      query: (body) => ({
        url: `/api/favorite`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Favorites"],
    }),
    removeToUniversitiesFavorites: builder.mutation({
      query: ({ specialization_id, university_id, faculty_id }) => ({
        url: `/api/favorite/?${
          specialization_id
            ? `specialization_id=${specialization_id}`
            : university_id
            ? `university_id=${university_id}`
            : `faculty_id=${faculty_id}`
        }`,
        method: "delete",
      }),
      invalidatesTags: ["Favorites"],
    }),
    getFavoritesUniversities: builder.query({
      query: (params) => ({
        url: `/api/favorite/universities`,
        params: { ...params },
      }),
      providesTags: ["Favorites"],
    }),
    FavoritesFaculities: builder.query({
      query: (params) => ({
        url: `/api/favorite/faculties`,
        params: { ...params },
      }),
      providesTags: ["Favorites"],
    }),
    FavoritesSpecialties: builder.query({
      query: (params) => ({
        url: `/api/favorite/specializations`,
        params: { ...params },
      }),
      providesTags: ["Favorites"],
    }),
  }),
});

export const {
  useAddToUniversitiesFavoritesMutation,
  useRemoveToUniversitiesFavoritesMutation,
  useGetFavoritesUniversitiesQuery,
  useFavoritesFaculitiesQuery,
  useFavoritesSpecialtiesQuery,
} = profileApiSlice;
